<template>
  <WeCard class="sticky-top mb-3 mb-xl-0">
    <h6 class="custom-card-title mb-3">
      <i class="fas fa-poll"></i>
      <span>Pozisyon</span>
    </h6>
    <div class="row mb-4">
      <!-- Cheapest -->
      <div class="col-12 mb-2 col-md-6 mb-md-0 pr-md-1">
        <WeCard
          class="bg-light border-0 text-center position-relative hover-shadow"
          body-class="pb-2 px-2 pt-3"
        >
          <!-- Info -->
          <div class="about-card">
            <i
              class="fas fa-info-circle ml-1 text-secondary"
              v-tooltip.bottom="
                'Rakipler arasında en ucuz ürün olduğu bilgisini verir'
              "
            ></i>
          </div>
          <!-- ./Info -->
          <i class="fas fa-crown text-success fa-lg"></i>
          <div class="d-block my-1">
            <small>En Ucuz</small>
          </div>
          <hr class="my-2" />
          <span class="font-weight-bold">{{ cheapest.length }} Ürün</span>
        </WeCard>
      </div>
      <!-- ./Cheapest -->

      <!-- The Most Expensive -->
      <div class="col-12 mb-2 col-md pl-md-1">
        <WeCard
          class="bg-light border-0 text-center position-relative hover-shadow"
          body-class="pb-2 px-2 pt-3"
        >
          <!-- Info -->
          <div class="about-card">
            <i
              class="fas fa-info-circle ml-1 text-secondary"
              v-tooltip.bottom="
                'Rakipler arasında en pahalı ürün olduğu bilgisini verir'
              "
            ></i>
          </div>
          <!-- ./Info -->
          <i class="fas fa-exclamation-triangle text-danger fa-lg"></i>
          <div class="d-block my-1">
            <small>En Pahalı</small>
          </div>
          <hr class="my-2" />
          <span class="font-weight-bold">{{ mostExpensive.length }} Ürün</span>
        </WeCard>
      </div>
      <!-- The Most Expensive -->

      <!-- Cheap -->
      <div class="col-12 mb-2 col-md-6 pr-md-1">
        <WeCard
          class="bg-light border-0 text-center position-relative hover-shadow"
          body-class="pb-2 px-2 pt-3"
        >
          <!-- Info -->
          <div class="about-card">
            <i
              class="fas fa-info-circle ml-1 text-secondary"
              v-tooltip.bottom="
                'Rakiplerin ortalama fiyatına göre ürünlerinizin durum bilgisini verir'
              "
            ></i>
          </div>
          <!-- ./Info -->
          <i class="far fa-thumbs-up text-success fa-lg"></i>
          <div class="d-block my-1">
            <small>Ucuz</small>
          </div>
          <hr class="my-2" />
          <span class="font-weight-bold">{{ cheap.length }} Ürün</span>
        </WeCard>
      </div>
      <!-- ./Cheap -->

      <!-- Expensive -->
      <div class="col-12 mb-2 col-md-6 pl-md-1">
        <WeCard
          class="bg-light border-0 text-center position-relative hover-shadow"
          body-class="pb-2 px-2 pt-3"
        >
          <!-- Info -->
          <div class="about-card">
            <i
              class="fas fa-info-circle ml-1 text-secondary"
              v-tooltip.bottom="
                'Rakiplerin ortalama fiyatına göre ürünlerinizin durum bilgisini verir'
              "
            ></i>
          </div>
          <!-- ./Info -->
          <i class="far fa-thumbs-down text-danger fa-lg"></i>
          <div class="d-block my-1">
            <small>Pahalı</small>
          </div>
          <hr class="my-2" />
          <span class="font-weight-bold">{{ expensive.length }} Ürün</span>
        </WeCard>
      </div>
      <!-- Expensive -->

      <!-- Equal -->
      <div class="col-12 mb-2 col-md-6 mb-md-0 pr-md-1">
        <WeCard
          class="bg-light border-0 text-center position-relative hover-shadow"
          body-class="pb-2 px-2 pt-3"
        >
          <!-- Info -->
          <div class="about-card">
            <i
              class="fas fa-info-circle ml-1 text-secondary"
              v-tooltip.bottom="
                'Rakiplerin ortalama fiyatına göre ürünlerinizin durum bilgisini verir'
              "
            ></i>
          </div>
          <!-- ./Info -->
          <i class="fas fa-equals text-primary fa-lg"></i>
          <div class="d-block my-1">
            <small>Eşit</small>
          </div>
          <hr class="my-2" />
          <span class="font-weight-bold">{{ equal.length }} Ürün</span>
        </WeCard>
      </div>
      <!-- ./Equal -->

      <!-- Average -->
      <div class="col-12 mb-2 col-md-6 mb-md-0 pl-md-1">
        <WeCard
          class="bg-light border-0 text-center position-relative hover-shadow"
          body-class="pb-2 px-2 pt-3"
        >
          <!-- Info -->
          <div class="about-card">
            <i
              class="fas fa-info-circle ml-1 text-secondary"
              v-tooltip.bottom="
                'Rakiplerin ortalama fiyatına göre ürünlerinizin durum bilgisini verir'
              "
            ></i>
          </div>
          <!-- ./Info -->
          <i class="fas fa-balance-scale-right text-primary fa-lg"></i>
          <div class="d-block my-1">
            <small>Ortalama Sıra</small>
          </div>
          <hr class="my-2" />
          <span class="font-weight-bold">{{ average | toFixed }}</span>
        </WeCard>
      </div>
      <!-- ./Average -->
    </div>
    <div class="row">
      <div class="col-12 mb-2 col-md mb-md-0">
        <h6 class="custom-card-title mb-3">
          <i class="fas fa-chart-line"></i>
          <span>Özet</span>
        </h6>
        <div class="row">
          <!-- Product -->
          <div class="col-12 mb-2 col-md-6 pr-md-1">
            <WeCard
              class="
                bg-light
                border-0
                text-center
                position-relative
                hover-shadow
              "
              body-class="pb-2 px-2 pt-3"
            >
              <i class="fas fa-cube text-success fa-lg"></i>
              <div class="d-block my-1">
                <small>Ürün</small>
              </div>
              <hr class="my-2" />
              <span class="font-weight-bold">{{
                priceAnalysis.products.length
              }}</span>
            </WeCard>
          </div>
          <!-- ./Product -->

          <!-- Competitor -->
          <div class="col-12 mb-2 col-md-6 pl-md-1">
            <WeCard
              class="
                bg-light
                border-0
                text-center
                position-relative
                hover-shadow
              "
              body-class="pb-2 px-2 pt-3"
            >
              <i class="fas fa-crosshairs text-danger fa-lg"></i>
              <div class="d-block my-1">
                <small>Rakip</small>
              </div>
              <hr class="my-2" />
              <span class="font-weight-bold">{{ competitorCount }}</span>
            </WeCard>
          </div>
          <!-- ./Competitor -->

          <!-- Cheap -->
          <div class="col-12 mb-2 col-md-6 mb-md-0 pr-md-1">
            <WeCard
              class="
                bg-light
                border-0
                text-center
                position-relative
                hover-shadow
              "
              body-class="pb-2 px-2 pt-3"
            >
              <i class="far fa-check-circle text-success fa-lg"></i>
              <div class="d-block my-1">
                <small>Stokta Var</small>
              </div>
              <hr class="my-2" />
              <span class="font-weight-bold">{{ inStock.length }}</span>
            </WeCard>
          </div>
          <!-- ./Cheap -->

          <!-- Expensive -->
          <div class="col-12 mb-2 col-md-6 mb-md-0 pl-md-1">
            <WeCard
              class="
                bg-light
                border-0
                text-center
                position-relative
                hover-shadow
              "
              body-class="pb-2 px-2 pt-3"
            >
              <i class="far fa-times-circle text-danger fa-lg"></i>
              <div class="d-block my-1">
                <small>Stokta Yok</small>
              </div>
              <hr class="my-2" />
              <span class="font-weight-bold">{{ outOfStock.length }}</span>
            </WeCard>
          </div>
          <!-- Expensive -->
        </div>
      </div>
    </div>
  </WeCard>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PriceStatistic",
  data() {
    return {
      cheapest: [],
      mostExpensive: [],
      cheap: [],
      expensive: [],
      equal: [],
      average: "-",
      inStock: [],
      outOfStock: [],
    };
  },
  methods: {
    setDefault() {
      this.cheapest = [];
      this.mostExpensive = [];
      this.cheap = [];
      this.expensive = [];
      this.equal = [];
      this.average = "-";
      this.inStock = [];
      this.outOfStock = [];
    },
    calculate() {
      this.setDefault();
      const products = this.products;
      const productOrders = [];

      products.forEach((product) => {
        const competitors = product.competitors || [];

        if (competitors.length) {
          const competitorPrices = [];

          // Rakip Fiyatları
          competitors.forEach((competitor) => {
            // Rakip stok mevcutsa fiyatlamaya dahil et
            if (competitor.availability) {
              const competitorPrice = competitor.hasOwnProperty(
                "convertedCurrency"
              )
                ? competitor.convertedPrice || 0
                : competitor.price;
              competitorPrices.push(competitorPrice);
            }

            if (
              competitor.availability &&
              !this.inStock.includes(competitor.id)
            ) {
              this.inStock.push(competitor.id);
            } else {
              if (!this.outOfStock.includes(competitor.id)) {
                this.outOfStock.push(competitor.id);
              }
            }
          });
          if (competitorPrices.length) {
            competitorPrices.push(Number(product.price));
          }

          // Rakip Fiyatları Küçükten büyüğe
          competitorPrices.sort((a, b) => a - b);

          // Ortalama Fiyat
          const averagePrice =
            competitorPrices && competitorPrices.length
              ? competitorPrices.reduce(function (a, b) {
                  return a + b;
                }) / competitorPrices.length
              : 0;

          // Fiyat konumu
          const priceOrder = competitorPrices.findIndex(
            (i) => i == product.price
          );

          if (priceOrder >= 0) {
            // Ortalama Sıra
            productOrders.push(priceOrder + 1);
            this.average =
              productOrders && productOrders.length
                ? productOrders.reduce(function (a, b) {
                    return a + b;
                  }) / productOrders.length
                : 0;

            // En ucuz fiyat
            if (priceOrder == 0 && !this.cheapest.includes(product.id)) {
              this.cheapest.push(product.id);
            }

            // En Pahalı Fiyat
            if (
              priceOrder == competitorPrices.length - 1 &&
              !this.mostExpensive.includes(product.id)
            ) {
              this.mostExpensive.push(product.id);
            }

            if (averagePrice > 0) {
              // Ucuz
              if (
                product.price < averagePrice &&
                !this.cheap.includes(product.id) &&
                !this.cheapest.includes(product.id)
              ) {
                this.cheap.push(product.id);
              }

              // Pahalı
              if (
                product.price > averagePrice &&
                !this.expensive.includes(product.id) &&
                !this.mostExpensive.includes(product.id)
              ) {
                this.expensive.push(product.id);
              }

              // Eşit
              if (
                product.price == averagePrice &&
                !this.equal.includes(product.id)
              ) {
                this.equal.push(product.id);
              }
            }
          }
        }
      });
    },
  },
  computed: {
    ...mapState(["priceAnalysis"]),
    products() {
      return this.priceAnalysis.products.filter(
        (product) => product.competitors && product.competitors.length
      );
    },
    competitorCount() {
      let result = 0;

      if (this.products && this.products.length) {
        this.products.forEach((p) => {
          result += p.competitors.length;
        });
      }

      return result;
    },
  },
  watch: {
    "priceAnalysis.products": {
      handler: function (newVal) {
        this.calculate();
      },
      deep: true,
    },
  },
  mounted() {
    this.calculate();
  },
};
</script>
<style lang="scss">
.about-card {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>